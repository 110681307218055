<template>
	<div class="product-table-summary mt-3 pt-2" data-test-id="product-table-summary">
		<div class="d-flex justify-content-between mb-1">
			<div class="label">
				Price before discount
			</div>
			<div class="typo-body-2">
				{{ subtotal }}
			</div>
		</div>
		<div
			v-if="hasStudentDiscount"
			class="d-flex justify-content-between mb-1"
		>
			<div
				:class="['label', { 'cursor-pointer' : hasStudentDiscount }]"
				@click="isShowStudentDiscount = !isShowStudentDiscount"
			>
				<span class="mr-2">Student discount</span>
				<template v-if="hasStudentDiscount">
					<FontAwesomeIcon
						v-if="isShowStudentDiscount"
						:icon="['fas', 'chevron-up']"
						class="icon-toggle"
						size="xs"
					/>
					<FontAwesomeIcon
						v-else
						:icon="['fas', 'chevron-down']"
						class="icon-toggle"
						size="xs"
					/>
				</template>
			</div>
			<div class="typo-body-2 color-alert">
				- {{ studentDiscountAmount }}
			</div>
		</div>
		<CCollapse :show="isShowStudentDiscount" class="cart-promotion-details">
			<div
				v-if="hasStudentDiscount"
				class="mb-3"
			>
				<div class="typo-body-2 color-black-45 mb-1">
					Apple program
				</div>
				<div>
					<div
						class="box can-interact"
						@click="showSummaryDetail('promotion', studentDiscount)"
					>
						<div class="typo-body-2 mr-3">
							{{ studentDiscount.name }}
						</div>
						<div class="text-nowrap price-discount">
							- {{ studentDiscount.discount }}
						</div>
					</div>
				</div>
			</div>
		</CCollapse>
		<div class="d-flex justify-content-between mb-1">
			<div
				:class="['label', { 'cursor-pointer' : hasDiscount }]"
				@click="isShowPromotionDiscount = !isShowPromotionDiscount"
			>
				<span class="mr-2">Discount</span>
				<template v-if="hasDiscount">
					<FontAwesomeIcon
						v-if="isShowPromotionDiscount"
						:icon="['fas', 'chevron-up']"
						class="icon-toggle"
						size="xs"
					/>
					<FontAwesomeIcon
						v-else
						:icon="['fas', 'chevron-down']"
						class="icon-toggle"
						size="xs"
					/>
				</template>
			</div>
			<div class="typo-body-2 color-alert">
				- {{ discount }}
			</div>
		</div>
		<CCollapse :show="isShowPromotionDiscount" class="cart-promotion-details">
			<div
				v-if="promotions.length"
				class="mb-3"
			>
				<div class="typo-body-2 color-black-45 mb-1">
					Promotion
				</div>
				<div>
					<div
						v-for="promotion in promotions"
						:key="promotion.id"
						class="box can-interact"
						@click="showSummaryDetail('promotion', promotion)"
					>
						<div class="typo-body-2 mr-3">
							{{ promotion.name }}
						</div>
						<div class="text-nowrap price-discount">
							- {{ promotion.discount }}
						</div>
					</div>
				</div>
			</div>
			<div
				v-if="coupons.length"
				class="mb-3"
			>
				<div class="typo-body-2 color-black-45 mb-1">
					Discount code
				</div>
				<div>
					<div
						v-for="coupon in coupons"
						:key="coupon.id"
						class="box"
					>
						<div class="typo-body-2">
							{{ coupon.code }}
						</div>
						<div class="text-nowrap price-discount">
							- {{ coupon.discount }}
						</div>
					</div>
				</div>
			</div>
		</CCollapse>
		<div class="d-flex justify-content-between mb-1">
			<div class="label">
				Subtotal
			</div>
			<div class="typo-body-2">
				{{ subtotalWithDiscountExcludedShippingDiscount }}
			</div>
		</div>
		<hr>
		<div class="d-flex justify-content-between mb-1">
			<div
				class="label cursor-pointer"
				@click="isShowShippingFee = !isShowShippingFee"
			>
				<span class="mr-2">Shipping fee ({{ shippingMethodName }})</span>
				<FontAwesomeIcon
					v-if="isShowShippingFee"
					:icon="['fas', 'chevron-up']"
					class="icon-toggle"
					size="xs"
				/>
				<FontAwesomeIcon
					v-else
					:icon="['fas', 'chevron-down']"
					class="icon-toggle"
					size="xs"
				/>
			</div>
			<div class="typo-body-2">
				{{ totalShippingFee }}
			</div>
		</div>
		<CCollapse :show="isShowShippingFee" class="cart-shipping-fee-details">
			<div class="cart-details-wrapper mb-3">
				<div class="box box-base-shipping-fee">
					<div class="base-shipping-fee-title">
						Base fee
					</div>
					<div class="text-nowrap">
						{{ baseShippingFee }}
					</div>
				</div>
			</div>
			<div class="cart-details-wrapper mb-3">
				<div class="additional-fee-title typo-body-2 color-black-45 mb-1">
					Additional fee
				</div>
				<div
					v-if="categoryFees.length"
					class="box can-interact"
					@click="showSummaryDetail(ADDITIONAL_FEE_TYPE.CATEGORY)"
				>
					<div>
						<div class="label mb-2">
							Categories
						</div>
						<div
							v-for="(categoryFee, index) in categoryFees"
							:key="index"
							class="path"
						>
							{{ categoryFee.path }}
						</div>
					</div>
					<div class="text-nowrap">
						{{ categoryTotalFee }}
					</div>
				</div>
				<div
					v-if="productFees.length"
					class="box can-interact"
					@click="showSummaryDetail(ADDITIONAL_FEE_TYPE.PRODUCT)"
				>
					<div>
						<div class="label mb-2">
							SKUs
						</div>
						<div
							v-for="(productFee, index) in productFees"
							:key="index"
							class="path"
						>
							{{ productFee.product.sku }}
						</div>
					</div>
					<div class="text-nowrap">
						{{ productTotalFee }}
					</div>
				</div>
				<div
					v-if="postcodeFees.length"
					class="box can-interact"
					@click="showSummaryDetail(ADDITIONAL_FEE_TYPE.POSTCODE)"
				>
					<div>
						<div class="label mb-2">
							Postcodes
						</div>
						<div
							v-for="(postcodeFee, index) in postcodeFees"
							:key="index"
							class="path"
						>
							{{ postcodeFee.postcode }}
						</div>
					</div>
					<div class="text-nowrap">
						{{ postcodeTotalFee }}
					</div>
				</div>

				<BaseNoItemBanner
					v-if="!categoryFees.length && !productFees.length && !postcodeFees.length"
					text="There is no additional fees"
				/>
			</div>
		</CCollapse>
		<div class="d-flex justify-content-between mb-1">
			<div
				class="label"
				@click="isShowShippingDiscount = !isShowShippingDiscount"
			>
				<span class="mr-2">Shipping discount</span>
				<FontAwesomeIcon
					v-if="isShowShippingDiscount"
					:icon="['fas', 'chevron-up']"
					size="xs"
				/>
				<FontAwesomeIcon
					v-else
					:icon="['fas', 'chevron-down']"
					size="xs"
				/>
			</div>
			<div class="typo-body-2 color-alert">
				- {{ shippingDiscountTotal }}
			</div>
		</div>
		<CCollapse :show="isShowShippingDiscount">
			<div class="mb-3">
				<div class="typo-body-2 color-black-45 mb-1">
					Promotion
				</div>
				<div v-if="shippingDiscountPromotions.length">
					<div
						v-for="promotion in shippingDiscountPromotions"
						:key="promotion.id"
						class="box"
					>
						<div class="typo-body-2 mr-3">
							{{ promotion.name }}
						</div>
						<div class="text-nowrap price-discount">
							- {{ promotion.discount }}
						</div>
					</div>
				</div>
				<BaseNoItemBanner v-else text="There is no shipping promotion" />
			</div>
			<div class="mb-3">
				<div class="typo-body-2 color-black-45 mb-1">
					Coupon
				</div>
				<div v-if="shippingDiscountCoupons.length">
					<div
						v-for="coupon in shippingDiscountCoupons"
						:key="coupon.id"
						class="box"
					>
						<div class="typo-body-2">
							{{ coupon.code }}
						</div>
						<div class="text-nowrap price-discount">
							- {{ coupon.discount }}
						</div>
					</div>
				</div>
				<BaseNoItemBanner v-else text="There is no shipping coupon" />
			</div>
		</CCollapse>
		<hr>
		<div class="total-price d-flex justify-content-between mb-1">
			<h6>
				Total price
			</h6>
			<h6>
				{{ total }}
			</h6>
		</div>
		<ModalOrderDetailSummary
			ref="modal-order-summary-detail"
			:title="modalTitle"
			:description="modalDescription"
			:fields="modalFields"
			:data="modalData"
			:type="modalType"
			:total-amount="modalTotalAmount"
		/>
	</div>
</template>

<script>
import ModalOrderDetailSummary from '@/components/ModalOrderDetailSummary.vue';
import { ADDITIONAL_FEE_TYPE } from '../enums/additionalFees';

export default {
	name: 'OrderDetailSummary',
	components: {
		ModalOrderDetailSummary,
	},
	props: {
		totalShippingFee: {
			type: String,
			default: null,
		},
		baseShippingFee: {
			type: String,
			default: null,
		},
		categoryTotalFee: {
			type: String,
			default: null,
		},
		categoryFees: {
			type: Array,
			default: () => [],
		},
		productTotalFee: {
			type: String,
			default: null,
		},
		productFees: {
			type: Array,
			default: () => [],
		},
		postcodeTotalFee: {
			type: String,
			default: null,
		},
		postcodeFees: {
			type: Array,
			default: () => [],
		},
		fullPrice: {
			type: String,
			default: null,
		},
		promotions: {
			type: Array,
			default: () => [],
		},
		coupons: {
			type: Array,
			default: () => [],
		},
		total: {
			type: String,
			default: null,
		},
		subtotal: {
			type: String,
			default: null,
		},
		subtotalWithDiscountExcludedShippingDiscount: {
			type: String,
			default: null,
		},
		discount: {
			type: String,
			default: null,
		},
		shippingMethodName: {
			type: String,
			default: null,
		},
		shippingDiscountPromotions: {
			type: Array,
			default: () => [],
		},
		shippingDiscountCoupons: {
			type: Array,
			default: () => [],
		},
		shippingDiscountTotal: {
			type: String,
			default: null,
		},
		studentDiscountAmount: {
			type: String,
			default: null,
		},
		studentDiscount: {
			type: Object,
			default: () => ({}),
		},
	},
	data() {
		return {
			isShowPromotionDiscount: false,
			isShowShippingFee: false,
			isShowShippingDiscount: false,
			isShowStudentDiscount: false,
			modalTitle: null,
			modalDescription: null,
			modalFields: [],
			modalData: [],
			modalTotalAmount: null,
			modalType: null,
		};
	},
	computed: {
		ADDITIONAL_FEE_TYPE: () => ADDITIONAL_FEE_TYPE,
		hasDiscount() {
			return this.promotions.length || this.coupons.length;
		},
		hasStudentDiscount() {
			return this.studentDiscount !== null;
		},
	},
	methods: {
		showSummaryDetail(type, promotion = {}) {
			switch (type) {
				case ADDITIONAL_FEE_TYPE.CATEGORY:
					this.modalTitle = 'Categories additional fee detail';
					this.modalFields = [
						{ key: 'path', label: 'Category', class: 'text-left', cellClass: 'text-left' },
						{ key: 'amount', label: 'Additional fee', class: 'text-right', cellClass: 'text-right typo-body-2' },
					];
					this.modalData = this.categoryFees;
					this.modalDescription = null;
					this.modalTotalAmount = this.categoryTotalFee;
					this.modalType = 'fee';
					break;
				case ADDITIONAL_FEE_TYPE.PRODUCT:
					this.modalTitle = 'SKUs additional fee detail';
					this.modalFields = [
						{ key: 'product', label: 'Product', class: 'text-left', cellClass: 'text-left' },
						{ key: 'amount', label: 'Additional fee', class: 'text-right', cellClass: 'text-right typo-body-2' },
					];
					this.modalData = this.productFees;
					this.modalDescription = null;
					this.modalTotalAmount = this.productTotalFee;
					this.modalType = 'fee';
					break;
				case ADDITIONAL_FEE_TYPE.POSTCODE:
					this.modalTitle = 'Postcode additional fee detail';
					this.modalFields = [
						{ key: 'postcode', label: 'Postcode', class: 'text-left', cellClass: 'text-left' },
						{ key: 'amount', label: 'Additional fee', class: 'text-right', cellClass: 'text-right typo-body-2' },
					];
					this.modalData = this.postcodeFees;
					this.modalDescription = null;
					this.modalTotalAmount = this.postcodeTotalFee;
					this.modalType = 'fee';
					break;
				case 'promotion':
					this.modalTitle = promotion.name;
					this.modalFields = [
						{ key: 'product', label: 'Product', class: 'text-left', cellClass: 'text-left' },
						{ key: 'quantity', label: 'Quantity', class: 'text-center', cellClass: 'text-center' },
						{ key: 'discount', label: 'Discount', class: 'th-mw-150 text-right', cellClass: 'text-right' },
					];

					this.modalData = promotion.items;
					this.modalDescription = promotion.description;
					this.modalTotalAmount = promotion.discount;
					this.modalType = 'promotion';

					break;
				default:
					return;
			}
			this.$refs['modal-order-summary-detail'].open();
		},
	},
};
</script>

<style lang="scss" scoped>
	.product-table-summary {
		max-width: rem(350);
		margin: 0 rem(16) rem(24) auto;

		.total-price {
			border-bottom: rem(3) solid $color-black;

			margin: rem(16) 0 0 0;
			padding: rem(16) 0;
		}
	}

	.box {
		@include typo-body-2;

		display: flex;
		justify-content: space-between;
		background-color: $color-gray-100;
		border-radius: rem(4) rem(4) 0 0;
		border-bottom: rem(1) solid $color-gray-300;
		padding: rem(16);

		&:last-child {
			border-radius: 0 0 rem(4) rem(4);
			border-bottom: none;
		}

		&.can-interact {
			cursor: pointer;

			&:hover {
				background-color: $color-gray-200;
			}
		}
	}

	.path {
		display: inline-block;
		border-radius: rem(4);
		background-color: $color-gray-300;
		padding: 0 rem(6);
		margin-bottom: rem(8);
		margin-right: rem(8);
	}

	.total-price {
		border-bottom: 3px solid $color-black;
		padding-bottom: rem(8);
	}


	/**
	* Print
	*/
	@media print {
		.icon-toggle {
			display: none;
		}

		.cart-promotion-details,
		.cart-shipping-fee-details {
			display: block !important;
		}

		.cart-details-wrapper {
			margin-bottom: rem(6) !important;
		}

		.box {
			background-color: transparent;
			padding: rem(5) 0 rem(5) rem(16);
			border-bottom: none;
		}

		.base-shipping-fee-title {
			color: $color-black-45;
		}

		.additional-fee-title {
			padding-left: rem(16);
		}
	}

	.price-discount {
		color: $color-alert;
	}
</style>
