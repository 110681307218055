<template>
	<div class="card card-order-payment custom-card mt-4 mb-0">
		<div class="card-header typo-h5 d-flex align-items-center">
			<span>Payment</span>
			<CBadge
				:color="paymentStatus.color"
				class="badge-payment-status"
				data-test-id="badge-payment-status"
			>
				{{ paymentStatus.title }}
			</CBadge>
		</div>
		<div v-if="isBnnStores" class="card-body">
			<CRow>
				<CCol>
					<OrderDetailPaymentInfo
						:payment="payment"
						:title="mainPaymentTitle"
						:is-updating="edit.isUpdating"
						:is-bnn-stores="isBnnStores"
						:show-mark-as-paid-button="showMarkAsPaidButton"
						:order-status="orderStatus"
					/>
					<!-- Remaining Payment -->
					<OrderDetailPaymentInfo
						v-if="paymentRemaining"
						:payment="paymentRemaining"
						:is-updating="edit.isUpdating"
						:is-bnn-stores="isBnnStores"
						:show-mark-as-paid-button="showRemainingMarkAsPaidButton"
						:is-partially-paid="isPartiallyPaid"
						:order-status="orderStatus"
						title="Remaining payment info"
						class="mt-5"
						remaining-payment
					/>
				</CCol>
				<CCol>
					<OrderAddressDetails
						title="Billing address"
						:address="billingAddress"
						@onEdit="handleEditBillingAddress"
					/>
					<OrderAddressDetails
						title="Tax address"
						:address="taxAddress"
						@onEdit="handleEditTaxAddress"
					/>
				</CCol>
			</CRow>
		</div>
	</div>
</template>

<script>
import { mapState } from 'vuex';
import OrderAddressDetails from '@/components/OrderAddressDetails.vue';
import OrderDetailPaymentInfo from '@/components/OrderDetailPaymentInfo.vue';
import {
	ORDER_COLOR,
	ORDER_DETAIL_ADDRESS_TYPE,
	ORDER_TITLE,
	ORDER_ITEM_TYPE,
	ORDER_STATUS,
} from '../enums/orders';
import { PAYMENT_STATUS, OFFLINE_PAYMENTS } from '../enums/payments';

export default {
	name: 'OrderDetailPayment',
	components: {
		OrderAddressDetails,
		OrderDetailPaymentInfo,
	},
	props: {
		payment: {
			type: Object,
			default: null,
		},
		paymentRemaining: {
			type: Object,
			default: null,
		},
		isBnnStores: {
			type: Boolean,
			default: false,
		},
		orderType: {
			type: String,
			default: null,
		},
		orderStatus: {
			type: String,
			default: null,
		},
	},
	data() {
		return {
			PAYMENT_STATUS,
		};
	},
	computed: {
		...mapState('orders', {
			edit: 'edit',
		}),
		paymentStatus() {
			const titleStatus = this.isPartiallyPaid
				? PAYMENT_STATUS.PARTIALLY_PAID
				: this.payment.status;

			return {
				title: ORDER_TITLE.PAYMENT[titleStatus],
				color: ORDER_COLOR.PAYMENT[this.payment.status],
			};
		},
		isPartiallyPaid() {
			return this.orderType === ORDER_ITEM_TYPE.PRE_ORDER
				&& (this.payment && this.payment.status === PAYMENT_STATUS.PAID)
				&& (this.paymentRemaining && this.paymentRemaining.status !== PAYMENT_STATUS.PAID);
		},
		isPaymentStatusExpired() {
			return this.payment.status === PAYMENT_STATUS.EXPIRED;
		},
		billingAddress() {
			return this.payment.billingAddress;
		},
		taxAddress() {
			return this.payment.taxAddress;
		},
		mainPaymentTitle() {
			return this.paymentRemaining ? 'Initial payment info' : 'Payment info';
		},
		showMarkAsPaidButton() {
			if (!this.isBnnStores) {
				return false;
			}

			// Hide mark as paid button when order status is canceled or expired
			if ([ORDER_STATUS.CANCELED, ORDER_STATUS.EXPIRED].includes(this.orderStatus)) {
				return false;
			}

			// Hide mark as paid button when payment status is expired or paid
			return ![
				PAYMENT_STATUS.EXPIRED,
				PAYMENT_STATUS.PAID,
			].includes(this.payment.status);
		},
		showRemainingMarkAsPaidButton() {
			/**
			 * Hide when
			 * - Store is not BNN
			 * - Main payment(initail payment) is not paid
			 * - Payment type of remaining payment is offline
			 */
			const isHide = [
				!this.isBnnStores,
				this.payment.status !== PAYMENT_STATUS.PAID,
				OFFLINE_PAYMENTS.includes(this.paymentRemaining?.method?.slug),
			].some((v) => v);

			if (isHide) {
				return false;
			}

			// Hide mark as paid button when payment status is expired or paid
			return ![
				PAYMENT_STATUS.EXPIRED,
				PAYMENT_STATUS.PAID,
			].includes(this.paymentRemaining.status);
		},
	},
	methods: {
		handleEditBillingAddress() {
			this.$emit('onClickEditAddress', {
				address: this.billingAddress,
				addressType: ORDER_DETAIL_ADDRESS_TYPE.BILLING,
			});
		},
		handleEditTaxAddress() {
			this.$emit('onClickEditAddress', {
				address: this.taxAddress,
				addressType: ORDER_DETAIL_ADDRESS_TYPE.TAX,
			});
		},
	},
};
</script>

<style lang="scss" scoped>
	.badge-payment-status {
		width: auto;
		padding: rem(7) rem(8);
		margin-left: rem(16);
		font-size: rem(14);
	}

	.btn-mark-as-paid {
		color: $color-hypertext;
		font-size: rem($font-size-body-2);
		font-weight: $font-weight-normal;

		&:hover {
			text-decoration: underline;
		}
	}

	/**
	* Print
	*/
	@media print {
		.badge-payment-status {
			border-color: transparent;
		}

		.btn-mark-as-paid {
			display: none;
		}
	}
</style>
