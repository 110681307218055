<template>
	<div>
		<BaseLoading v-if="isLoading" is-full-page />
		<div
			v-else
			class="main-wrapper-large"
		>
			<!-- General information -->
			<CRow>
				<CCol>
					<div class="order-header">
						<div class="order-header-primary">
							<h2 class="typo-h4 mt-4 d-flex align-items-center">
								Order ID #{{ order.orderCode }}
								<CBadge
									:color="orderBadge.color"
									class="badge-status mr-auto"
									data-test-id="badge-order-status"
								>
									{{ orderBadge.title }}
								</CBadge>
							</h2>
							<div class="summary">
								<p v-if="!isBnnStores" class="order-meta mb-1 p-0">
									Order ID (from channel): <span class="order-meta-value">{{ order.externalOrderCode }}</span>
								</p>
								<p class="order-meta mb-1 p-0">
									Order type: <span class="order-meta-value">{{ ORDER_ITEM_TYPE_TITLE[order.orderType] }}</span>
								</p>
								<p class="order-meta mb-1 p-0">
									Last updated by: <span class="color-hypertext">back office system</span><span class="date order-meta-value">{{ order.updatedAt }}</span>
								</p>
								<p class="order-meta">
									Placed from: <span class="order-meta-value">{{ order.store.name }}</span><span class="date order-meta-value">{{ order.createdAt }}</span>
								</p>
								<p v-if="order.ustoreInfo" class="order-meta mb-1 p-0">
									EC Code: <span class="order-meta-value">{{ order.ustoreInfo.ecCode }}</span>
								</p>
								<p v-if="order.ustoreInfo" class="order-meta p-0">
									University: <span class="order-meta-value">({{ order.ustoreInfo.university.appleCustomerId }}) {{ order.ustoreInfo.university.name }}</span>
								</p>
							</div>
						</div>
						<div class="btn-actions ml-2">
							<div v-if="isShowMarkAsButton" class="btn-actions-primary">
								<CDropdown
									placement="bottom-end"
									class="btn-mark-as mb-2 mr-2"
									color="primary"
								>
									<CDropdownItem
										v-if="isShowMarkAsReadyToPickup"
										@click="$refs[modalConfirmReadyToPickup].open()"
									>
										Ready to pickup
									</CDropdownItem>
									<CDropdownItem
										v-if="isShowMarkAsPickup"
										@click="$refs[modalConfirmPickedUp].open()"
									>
										Picked up
									</CDropdownItem>
									<CDropdownItem
										v-if="isShowMarkAsComplete"
										@click="$refs[modalConfirmCompleted].open()"
									>
										Completed
									</CDropdownItem>
									<template #toggler-content>
										Mark as
									</template>
								</CDropdown>
							</div>
							<CButton
								v-if="$refs[modalEmail] && $refs[modalEmail].emailDescription"
								color="tertiary"
								class="btn-action-email"
								@click="openModalConfirmSendEmail"
							>
								<CIcon name="cil-envelope-closed" /> Email
							</CButton>
							<CButton
								v-if="$refs[modalSms] && $refs[modalSms].smsDescription"
								color="tertiary"
								class="btn-action-sms"
								@click="openModalConfirmSendSms"
							>
								<CIcon name="cil-speech" /> SMS
							</CButton>
							<CButton
								color="tertiary"
								class="btn-action-print"
								@click="printOrder"
							>
								<CIcon name="cil-print" /> Print
							</CButton>
							<CButton
								v-if="isShowCancelOrderBtn"
								:disabled="edit.isUpdating"
								color="tertiary"
								class="btn-action-cancel"
								@click="openModalCancel()"
							>
								Cancel
							</CButton>
							<CButton
								v-if="isShowReviveOrderButton"
								:disabled="edit.isUpdating"
								color="tertiary"
								class="btn-revive-order"
								data-test-id="btn-revive-order"
								@click="openModalReviveOrder"
							>
								Revive order
							</CButton>
						</div>
					</div>
				</CCol>
			</CRow>
			<CRow class="customer">
				<CCol md="12">
					<h3 class="typo-h5 mt-3">
						Customer
					</h3>
					<p class="typo-h6 mb-1">
						{{ `${order.customer.firstName} ${order.customer.lastName}` }}
					</p>
					<div class="d-inline-block">
						<label class="typo-label color-black-45">
							ID: <span class="typo-body-2 font-weight-normal color-black">{{ order.customer.id }}</span>
						</label>
						<label class="typo-label color-black-45 ml-4">
							Customer group: <span class="typo-body-2 font-weight-normal color-black">{{ order.customer.customerGroup.name }}</span>
						</label>
						<label class="typo-label color-black-45 ml-4">
							Phone: <span class="typo-body-2 font-weight-normal color-black">{{ order.customer.phoneNumber }}</span>
						</label>
						<label class="typo-label color-black-45 ml-4">
							Email: <span class="typo-body-2 font-weight-normal color-black">{{ order.customer.email }}</span>
						</label>
					</div>
					<div v-if="order.customer.idCard" class="d-block">
						<label class="typo-label color-black-45">
							ID card no. / Passport no.: <span class="typo-body-2 font-weight-normal color-black">{{ order.customer.idCard }}</span>
						</label>
					</div>
					<div>
						<label class="typo-label color-black-45">
							Purchase for Organization purpose:
							<span class="typo-body-2 font-weight-normal color-black">{{ isOrganizationPurpose }}</span>
						</label>
					</div>

					<template v-if="hasCartTemporary">
						<h3 class="typo-h5 mt-3">
							Branch Staff (for commission)
						</h3>
						<p class="typo-h6 mb-1" data-test-id="cart-temporary-staff-name">
							{{ order.cartTemporary.staffName || '-' }}
						</p>
						<div class="d-inline-block">
							<label class="typo-label color-black-45">
								Account ID:
								<span class="typo-body-2 font-weight-normal color-black">
									{{ order.cartTemporary.staffCustomerId || '-' }}
								</span>
							</label>
							<label class="typo-label color-black-45 ml-4">
								Staff ID:
								<span class="typo-body-2 font-weight-normal color-black">
									{{ order.cartTemporary.staffId || '-' }}
								</span>
							</label>
							<label class="typo-label color-black-45 ml-4">
								Email:
								<span class="typo-body-2 font-weight-normal color-black">
									{{ order.cartTemporary.staffEmail || '-' }}
								</span>
							</label>
							<label class="typo-label color-black-45 ml-4">
								Branch ID:
								<span class="typo-body-2 font-weight-normal color-black">
									{{ order.cartTemporary.branchId || '-' }}
								</span>
							</label>
						</div>
					</template>

					<p class="typo-h6 mt-2 mb-1">
						Additional information
					</p>
					<p
						class="customer-note w-50 typo-body-2 color-black-45"
						data-test-id="customer-note"
					>
						{{ order.note | nullableToDashFormat }}
					</p>
				</CCol>
			</CRow>
			<!-- End General information -->

			<!-- Table -->
			<CRow>
				<CCol md="12">
					<div
						class="product-table mt-4"
						data-test-id="product-table"
					>
						<OrderDetailProductTable
							:fields="orderDetailTableFields"
							:items="order.items"
							table-type="product"
						/>
						<OrderDetailProductTable
							v-if="order.freebies.length"
							:fields="ORDER_DETAIL_TABLE_FREEBIE_FIELDS"
							:items="order.freebies"
							table-type="freebie"
						/>
						<OrderDetailSummary
							:subtotal-with-discount-excluded-shipping-discount="order.subtotalWithDiscountExcludedShippingDiscount"
							:subtotal="order.subtotal"
							:shipping-method-name="order.shipping.shippingMethodName"
							:discount="order.discount"
							:student-discount-amount="order.ustoreInfo ? order.ustoreInfo.studentDiscountAmount : null"
							:student-discount="order.studentDiscount"
							:promotions="order.promotions"
							:coupons="order.coupons"
							:total-shipping-fee="order.shippingFee"
							:base-shipping-fee="order.baseShippingFee"
							:category-total-fee="order.categoryTotalFee"
							:category-fees="order.categoryFees"
							:product-total-fee="order.productTotalFee"
							:product-fees="order.productFees"
							:postcode-total-fee="order.postcodeTotalFee"
							:postcode-fees="order.postcodeFees"
							:shipping-discount-promotions="order.shippingDiscountPromotions"
							:shipping-discount-coupons="order.shippingDiscountCoupons"
							:shipping-discount-total="order.shippingDiscountTotal"
							:total="order.total"
						/>
						<template v-if="order.flashSales.length">
							<OrderDetailFlashSale
								v-for="flashSale in order.flashSales"
								:key="flashSale.id"
								:items="flashSale.items"
								:name="flashSale.name"
								:total-discount="flashSale.totalDiscount"
							/>
						</template>
					</div>
				</CCol>
			</CRow>
			<!-- End Table -->

			<!-- Payment -->
			<CRow>
				<CCol>
					<OrderDetailPayment
						:payment="order.payment"
						:payment-remaining="order.paymentRemaining"
						:order-type="order.orderType"
						:order-status="order.orderStatus"
						:is-bnn-stores="isBnnStores"
						@onClickEditAddress="handleEditAddress"
					/>
				</CCol>
			</CRow>
			<!-- End Payment -->

			<!-- Shipment -->
			<CRow class="mb-5">
				<CCol>
					<OrderDetailShipping
						:order="order"
						:is-bnn-stores="isBnnStores"
						@onClickEditAddress="handleEditAddress"
						@onCreateShipment="$refs[modalCreateShipment].open()"
					/>
				</CCol>
			</CRow>
			<CRow class="order-history-remark">
				<CCol>
					<h5>
						Order history
					</h5>
					<OrderDetailHistory
						v-for="history in orderHistories"
						:key="history.id"
						:title="history.title"
						:status="history.level"
						:message="history.message"
						:created-by="history.actorName"
						:created-at="history.createdAt"
					/>
					<CButton
						v-if="order.histories.length > LATEST_FOUR_HISTORIES"
						color="tertiary"
						class="mt-4"
						@click="isShowAllHistory = !isShowAllHistory"
					>
						{{ isShowAllHistory ? 'Show less history' : 'Show all history' }}
					</CButton>
				</CCol>
				<CCol>
					<h5>
						Remark
					</h5>
					<RemarkCreateAndList
						:remarks="order.remarks"
						:is-creating="remark.isCreating"
						@onAddRemark="handleAddRemark"
					/>
				</CCol>
			</CRow>

			<!-- End Shipment -->
			<ModalOrderAddress
				:ref="modalEditAddress"
				:order-id="orderId"
				:has-tax-info="addressType === ORDER_DETAIL_ADDRESS_TYPE.TAX"
				:address-type="addressType"
				:address-id="addressId"
				:first-name.sync="contactInfo.firstName"
				:last-name.sync="contactInfo.lastName"
				:phone-number.sync="contactInfo.phoneNumber"
				:tax-type.sync="taxInfo.type"
				:tax-id.sync="taxInfo.taxId"
				:tax-email.sync="taxInfo.taxEmail"
				:company-name.sync="taxInfo.companyName"
				:branch-name.sync="taxInfo.branchName"
				:branch-id.sync="taxInfo.branchId"
				:address1.sync="addressInfo.address1"
				:address2.sync="addressInfo.address2"
				:postcode.sync="addressInfo.postcode"
				:province.sync="addressInfo.province"
				:district.sync="addressInfo.district"
				:subdistrict.sync="addressInfo.subdistrict"
				:delivery-detail.sync="addressInfo.deliveryDetail"
				is-required
			/>

			<ModalShipmentCreate
				:ref="modalCreateShipment"
				:items.sync="shipmentItems"
			/>

			<BaseModalConfirm
				:ref="modalConfirmPickedUp"
				:is-submitting="edit.isUpdating"
				:close-after-confirm="false"
				title="Confirm mark as 'Picked up'?"
				description="Order status will be changed to 'Completed' and it can't be undone."
				primary-button-text="Save"
				primary-button-loading-text="Saving"
				@onConfirm="handleConfirmPickedUp"
			/>

			<BaseModalConfirm
				:ref="modalConfirmCompleted"
				:is-submitting="edit.isUpdating"
				:close-after-confirm="false"
				title="Confirm mark as 'Completed'?"
				description="Are you sure to change status to 'Completed', this cannot be undone"
				primary-button-text="Save"
				primary-button-loading-text="Saving"
				@onConfirm="handleConfirmUpdateOrderStatus(modalConfirmCompleted, ORDER_STATUS.COMPLETED)"
			/>

			<BaseModalConfirm
				:ref="modalConfirmReadyToPickup"
				:is-submitting="edit.isUpdating"
				:close-after-confirm="false"
				title="Confirm mark as 'Ready to pickup'?"
				description="Are you sure to change status to 'Ready to pickup', this cannot be undone"
				primary-button-text="Save"
				primary-button-loading-text="Saving"
				@onConfirm="handleConfirmUpdateOrderStatus(modalConfirmReadyToPickup, ORDER_STATUS.READY_TO_PICK_UP)"
			/>

			<ModalCancelOrder
				:ref="modalCancel"
				title="Cancel entire order"
				description="Order status will be changed to 'Completed' and it can't be undone."
				:order-id="orderId"
				@onConfirm="handleConfirmCancelOrder"
			/>

			<ModalEditPaymentExpire
				:ref="modalReviveOrder"
				:is-submitting="isUpdatingReviveOrder"
				title="Revive order"
				@onConfirm="handleUpdateReviveOrder"
			/>
		</div>
		<OrderDetailEmail :ref="modalEmail" :order="order" />
		<OrderDetailSms :ref="modalSms" :order="order" />
	</div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import OrderDetailPayment from '@/components/OrderDetailPayment.vue';
import OrderDetailProductTable from '@/components/OrderDetailProductTable.vue';
import OrderDetailShipping from '@/components/OrderDetailShipping.vue';
import OrderDetailHistory from '@/components/OrderDetailHistory.vue';
import OrderDetailSummary from '@/components/OrderDetailSummary.vue';
import OrderDetailEmail from '@/components/OrderDetailEmail.vue';
import OrderDetailFlashSale from '@/components/OrderDetailFlashSale.vue';
import OrderDetailSms from '@/components/OrderDetailSms.vue';
import BaseModalConfirm from '@/components/BaseModalConfirm.vue';
import ModalCancelOrder from '@/components/ModalCancelOrder.vue';
import ModalOrderAddress from '@/components/ModalOrderAddress.vue';
import ModalShipmentCreate from '@/components/ModalShipmentCreate.vue';
import RemarkCreateAndList from '@/components/RemarkCreateAndList.vue';
import ModalEditPaymentExpire from '@/components/ModalEditPaymentExpire.vue';
import { convertDateTimeToUTC } from '../assets/js/helpers';
import {
	ORDER_COLOR,
	ORDER_TITLE,
	ORDER_DETAIL_TABLE_FIELDS,
	ORDER_DETAIL_TABLE_PREORDER_FIELDS,
	ORDER_DETAIL_TABLE_FREEBIE_FIELDS,
	ORDER_DETAIL_ADDRESS_TYPE,
	ORDER_DETAIL_TAX_TYPE,
	ORDER_STORE_BNN_ID,
	ORDER_STORE_STUDIO_7_ID,
	ORDER_STORE_USTORE_ID,
	ORDER_STATUS,
	ORDER_ITEM_TYPE_TITLE,
} from '../enums/orders';
import { SHIPPING_STATUS, SHIPPING_METHOD_LABELS_TYPE_DELIVER } from '../enums/shippings';
import { PAYMENT_STATUS, OFFLINE_PAYMENTS } from '../enums/payments';

export default {
	name: 'OrderDetail',
	components: {
		OrderDetailPayment,
		OrderDetailProductTable,
		OrderDetailShipping,
		OrderDetailHistory,
		OrderDetailSummary,
		OrderDetailEmail,
		OrderDetailSms,
		OrderDetailFlashSale,
		BaseModalConfirm,
		ModalOrderAddress,
		ModalShipmentCreate,
		RemarkCreateAndList,
		ModalCancelOrder,
		ModalEditPaymentExpire,
	},

	data() {
		return {
			isShowAllHistory: false,
			shipmentItems: [],
			orderId: Number(this.$route.params.id),
			addressId: null,
			contactInfo: {
				firstName: null,
				lastName: null,
				phoneNumber: null,
			},
			taxInfo: {
				type: null,
				taxId: null,
				taxEmail: null,
				companyName: null,
				branchName: null,
				branchId: null,
			},
			addressInfo: {
				address1: null,
				address2: null,
				postcode: null,
				province: null,
				district: null,
				subdistrict: null,
				deliveryDetail: null,
			},
			addressType: null,
			ORDER_STATUS,
			ORDER_DETAIL_ADDRESS_TYPE,
			ORDER_DETAIL_TABLE_FIELDS,
			ORDER_DETAIL_TABLE_PREORDER_FIELDS,
			ORDER_DETAIL_TABLE_FREEBIE_FIELDS,
			ORDER_ITEM_TYPE_TITLE,
			isUpdatingReviveOrder: false,
			modalConfirmReadyToPickup: 'modal-confirm-ready-to-pickup',
			modalConfirmPickedUp: 'modal-confirm-picked-up',
			modalConfirmCompleted: 'modal-confirm-completed',
			modalCancel: 'modal-cancel',
			modalEditAddress: 'modal-edit-address',
			modalCreateShipment: 'modal-create-shipment',
			modalEmail: 'modal-email',
			modalSms: 'modal-sms',
			modalReviveOrder: 'modal-revive-order',
		};
	},

	computed: {
		...mapState('orders', {
			detail: 'detail',
			remark: 'remark',
			edit: 'edit',
			reason: 'reason',
		}),
		...mapGetters({
			order: 'orders/orderDetail',
		}),
		LATEST_FOUR_HISTORIES: () => 4,
		orderHistories() {
			return this.isShowAllHistory
				? this.order.histories
				: this.order.histories.slice(0, this.LATEST_FOUR_HISTORIES);
		},
		isLoading() {
			return this.detail.isLoading;
		},
		orderBadge() {
			return {
				title: ORDER_TITLE.ORDER[this.order.orderStatus],
				color: ORDER_COLOR.ORDER[this.order.orderStatus],
			};
		},
		isBnnStores() {
			const bnnStores = [
				ORDER_STORE_BNN_ID,
				ORDER_STORE_STUDIO_7_ID,
				ORDER_STORE_USTORE_ID,
			];
			return bnnStores.includes(this.order.store.id);
		},
		isShowCancelOrderBtn() {
			return this.order.isCancelable;
		},
		isPreorder() {
			return this.order.items.some((item) => item.type === 'pre_order');
		},
		orderDetailTableFields() {
			if (this.isPreorder) {
				return ORDER_DETAIL_TABLE_PREORDER_FIELDS;
			}
			return ORDER_DETAIL_TABLE_FIELDS;
		},
		isShowReviveOrderButton() {
			return this.order.orderStatus === ORDER_STATUS.EXPIRED;
		},
		isOfflinePayment() {
			return OFFLINE_PAYMENTS.includes(this.order.payment.method.slug);
		},
		isRemainingOfflinePayment() {
			return OFFLINE_PAYMENTS.includes(this.order?.paymentRemaining?.method?.slug);
		},
		isPaid() {
			return this.order?.paymentStatus === PAYMENT_STATUS.PAID;
		},
		isPartiallyPaid() {
			if (!this.order.paymentRemaining) {
				return false;
			}

			return this.order.paymentRemaining.status === PAYMENT_STATUS.PAID;
		},
		isShowMarkAsPickup() {
			return this.order.orderStatus === ORDER_STATUS.READY_TO_PICK_UP;
		},
		isShowMarkAsReadyToPickup() {
			return this.order.orderStatus === ORDER_STATUS.PREPARING
				&& !SHIPPING_METHOD_LABELS_TYPE_DELIVER.includes(this.order.shipping.shippingMethodName);
		},
		isShowMarkAsComplete() {
			const orderStatus = [ORDER_STATUS.PREPARING, ORDER_STATUS.PARTIALLY_SHIPPED, ORDER_STATUS.SHIPPED];
			return orderStatus.includes(this.order.orderStatus);
		},
		isShowMarkAsButton() {
			return this.isShowMarkAsPickup || this.isShowMarkAsComplete;
		},
		isOrganizationPurpose() {
			return this.order.isOrganizationPurpose ? 'Yes' : 'No';
		},
		hasCartTemporary() {
			return this.order.cartTemporary ?? null;
		},
	},

	async created() {
		await this.getOrder(this.orderId);
		this.setPageTitle({
			title: `Order #${this.order.orderCode}`,
			isShow: true,
		});

		const products = [];

		// Combine main product + freebie
		this.order.items.forEach((item) => {
			const totalChildren = item.children.length;

			products.push(item);

			if (totalChildren) {
				item.children.forEach((itemChildren) => {
					products.push(itemChildren);
				});
			}
		});

		// All items to packed
		const allItems = [
			...products,

			// Cart freebie
			...this.order.freebies,
		];

		this.shipmentItems = [
			...allItems
				.map((item, index) => {
					const packedAmount = this.order.shipments.reduce((acc, shipment) => {
						const { READY_TO_BOOK_LOGISTIC, READY_TO_SHIP, SHIPPED, DELIVERED, BOOKING_FAILED } = SHIPPING_STATUS;
						const isReadyToCreateShipment = [READY_TO_BOOK_LOGISTIC, READY_TO_SHIP, SHIPPED, DELIVERED, BOOKING_FAILED].includes(shipment.status);
						const findExistsShipingItem = shipment.items.find((shipmentItem) => shipmentItem.orderItemId === item.id);
						const existsItem = isReadyToCreateShipment && findExistsShipingItem;

						return existsItem
							? acc + existsItem.quantity
							: acc;
					}, 0);

					return {
						itemIndex: index,
						isSelected: false,
						product: {
							id: item.id,
							thumbnail: item.thumbnail,
							name: item.name,
							sku: item.sku,
							type: item.type,
						},
						quantity: item.quantity,
						notPacked: item.quantity - packedAmount,
						quantityPacked: 0,
					};
				})
				.filter((item) => item.notPacked),
		];
	},

	methods: {
		...mapActions({
			getOrder: 'orders/getOrder',
			updateOrder: 'orders/updateOrder',
			updatePickedUp: 'orders/updatePickedUp',
			setPageTitle: 'pageTitle/setPageTitle',
			createOrderRemark: 'orders/createOrderRemark',
			postCancelOrder: 'orders/postCancelOrder',
		}),
		resetFormState() {
			this.addressId = null;
			this.contactInfo = {
				firstName: null,
				lastName: null,
				phoneNumber: null,
			};
			this.taxInfo = {
				type: ORDER_DETAIL_TAX_TYPE.PERSONAL,
				taxId: null,
				taxEmail: null,
				companyName: null,
				branchName: null,
				branchId: null,
			};
			this.addressInfo = {
				address1: null,
				address2: null,
				postcode: null,
				province: null,
				district: null,
				subdistrict: null,
				deliveryDetail: null,
			};
		},
		async handleConfirmPickedUp() {
			const id = this.$route.params.id;
			const orderStatus = {
				status: ORDER_STATUS.PICKED_UP,
			};

			/**
			 * Not update payment status to paid
			 * when
			 * - Payment status is paid
			 * - Payment status is partially paid and remaining payment
			 *   type is offline
			 */
			const isPartiallyPaid = this.order?.paymentStatus === PAYMENT_STATUS.PARTIALLY_PAID;
			const isNotUpdatePayment = [
				this.isPaid,
				isPartiallyPaid && this.isRemainingOfflinePayment,
			].some((v) => v);

			const paymentStatus = isNotUpdatePayment
				? null
				: { payment_status: PAYMENT_STATUS.PAID };

			await this.updatePickedUp({ id, params: { orderStatus, paymentStatus } });
		},
		async handleConfirmCancelOrder(orderId, params) {
			this.postCancelOrder({ id: orderId, params });
		},
		handleEditAddress({ address, addressType }) {
			this.resetFormState();
			this.addressType = addressType;
			if (address) {
				this.addressId = address.id;
				this.contactInfo = {
					firstName: address.firstName,
					lastName: address.lastName,
					phoneNumber: address.phoneNumber,
				};
				this.taxInfo = {
					type: address.taxType,
					taxEmail: address.taxEmail,
					taxId: address.isJuristic
						? address.taxId
						: address.nationalId,
					companyName: address.companyName,
					branchName: address.taxBranchName,
					branchId: address.taxBranchCode,
				};
				this.addressInfo = {
					address1: address.address1,
					address2: address.address2,
					postcode: address.postcode,
					province: address.province,
					district: address.district,
					subdistrict: address.subdistrict,
					deliveryDetail: address.detail,
				};
			}

			this.$refs[this.modalEditAddress].open();
		},
		handleAddRemark(message) {
			const orderId = this.$route.params.id;
			const params = { message };
			this.createOrderRemark({ id: orderId, params });
		},
		printOrder() {
			window.print();
		},
		openModalConfirmSendEmail() {
			this.$refs[this.modalEmail].$refs['modal-confirm-send-email'].open();
		},
		openModalConfirmSendSms() {
			this.$refs[this.modalSms].$refs['modal-confirm-send-sms'].open();
		},
		openModalCancel() {
			this.$refs[this.modalCancel].open();
		},
		openModalReviveOrder() {
			this.$refs[this.modalReviveOrder].open();
		},
		async handleUpdateReviveOrder({ expireDate, expireTime }) {
			const orderId = this.$route.params.id;
			const expireDateTime = convertDateTimeToUTC(expireDate, expireTime);

			try {
				this.isUpdatingReviveOrder = true;
				const updateStatusPayment = this.isOfflinePayment ? ORDER_STATUS.PREPARING : ORDER_STATUS.CREATED;

				const isRevivingRemainingPayment = this.order.payment.status === PAYMENT_STATUS.PAID && this.order.paymentRemaining?.status === PAYMENT_STATUS.EXPIRED;
				const paymentExpiredAtKey = isRevivingRemainingPayment ? 'remaining_payment_expired_at' : 'payment_expired_at';

				const params = {
					status: updateStatusPayment,
					[paymentExpiredAtKey]: expireDateTime,
				};

				await this.updateOrder({ id: orderId, params });
			} finally {
				this.isUpdatingReviveOrder = false;
			}
		},
		async handleConfirmUpdateOrderStatus(ref, status) {
			try {
				const orderId = this.$route.params.id;
				const params = { status };

				await this.updateOrder({ id: orderId, params });
			} finally {
				this.$refs[ref].close();
			}
		},
	},
};
</script>

<style lang="scss" scoped>
	.order-meta {
		@include typo-body-2;

		// order-meta-value
		&-value {
			color: $color-black-45;
		}
	}

	.order-header {
		display: flex;
		justify-content: space-between;
		align-items: flex-start;
	}

	.summary {
		.date {
			&::before {
				content: "|";
				display: inline-block;
				margin-left: rem(5);
				margin-right: rem(5);
			}
		}
	}

	.customer {
		// .customer-note
		&-note {
			border-radius: rem(4);
			min-height: rem(72);
			padding: rem(16);

			background-color: $color-gray-100;
		}
	}

	.badge-status {
		width: auto;
		padding: rem(7) rem(8);
		margin-left: rem(16);
		font-size: rem(14);
	}

	.product-table {
		border: rem(1) solid $color-gray-300;
		border-radius: rem(4);

		.product-cel {
			.product-info {
				display: flex;

				.thumbnail {
					flex: 0 0 rem(40);

					border-radius: rem(4);
					border: rem(1) solid $color-gray-300;
				}

				.description {
					flex: 1;
					margin-left: rem(16);
				}
			}
		}

		.empty-table-element {
			margin: 0;
		}
	}

	.border-left {
		border-left: rem(1) solid $color-gray-300;
	}

	.btn-actions {
		display: flex;
		justify-content: flex-end;
		flex-wrap: wrap;
		margin-top: rem(24);
		margin-left: rem(16);
		max-width: rem(400);

		&-primary {
			width: 100%;
			text-align: right;
		}

		button {
			margin-right: rem(8);
			margin-bottom: rem(10);
			height: rem(34);

			svg {
				margin-right: rem(8);
			}
		}
	}

	.btn-action-email {
		svg {
			position: relative;
			top: rem(-2);
		}
	}

	/**
	* Print
	*/
	@media print {
		.btn-actions,
		.order-history-remark {
			display: none;
		}

		.customer {
			// .customer-note
			&-note {
				min-height: auto;
				border-radius: 0;
				padding: rem(4) 0 rem(30);
				background-color: transparent;
				margin-bottom: 0 !important;
			}
		}
	}
</style>
